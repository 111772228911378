import styled, { themeGet } from "@styled";
import quoteBG from "@data/images/bg/quote-bg.png";
export const MachineTranslationWrapper = styled.section`
  padding: 90px 0;
  background-color: #f7f7f7;
  position: relative;
  .section-title {
    p {
      color: #fff;
    }
  }
     &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    opacity: 0.05;
    background: url(${quoteBG});
    background-size: contain;
    background-repeat: no-repeat;
    right: -100px;
    top: 200px; 
`;
export const MachineTranslationInner = styled.div``;
export const MachineTranslationBlock = styled.div`
  border: 1px solid rgba(210,210,210,0.55);
  border-radius: 4px;
  background: rgb(255 255 255 / 11%);
  margin: 0 0 0px;
  height:100%;
`;
export const MachineTranslationBlockInner = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  min-height: 190px;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
  .mac-trs-iconwrap {
    min-width: 140px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mac-trs-textwrap {
    // border-left: 1px solid #917ba8;
    padding: 0 24px;
    h6 {
      // color: #fff;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      // color: #ccb8fe;
      font-size: 15px;
    }
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 34px;
`;

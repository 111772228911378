import styled, {  device } from "@styled";
import quoteBG from "@data/images/bg/quote-bg.png";

export const DiveDeeperWrapper = styled.section`
  padding: 90px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    opacity: 0.05;
    background: url(${quoteBG});
    background-size: contain;
    background-repeat: no-repeat;
    left: 15px;
    bottom: 100px;
`;
export const DiveDeeperInner = styled.div`
  .accordion {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;

    ${device.large} {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 15px;
    }
  }
`;

import React from "react";
import PropTypes from "prop-types";
import Heading from "@ui/heading";
import Image from "@ui/image";
import { HeadingType, TextType, ButtonType, ImageType } from "@utils/types";
import {
  HeroWrapper,
  HeroWrapperText,
  HeroWrapperTextTop,
  HerologosList,
  HerologosItem,
  StyledBG,
  HeroCenterIcon,
} from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { PageHeaderImgWrap } from "../../page-header/layout-01/style";
import GlobalRating from "@components/global-rating";
import HeroQuote from "../../../components/hero-quote-form";
const HeroArea = ({ data }) => {
  const tabActive = (id) => {
    if (!!document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  return (
    <HeroWrapper>
      <Container>
        <Row className="h-100 position-relative">
          <Col lg={6}>
            <HeroWrapperText>
              <HeroWrapperTextTop>
                <Heading as="h3">{data.texts[0]?.content}</Heading>
                <Heading
                  as="h1"
                  dangerouslySetInnerHTML={{ __html: data.texts[1]?.content }}
                ></Heading>
                <Heading>{data.texts[3]?.content}</Heading>
                <HeroQuote />
              </HeroWrapperTextTop>
              <GlobalRating pageHeader={data?.id}></GlobalRating>
              <HerologosList>
                {data &&
                  data.items[0].images.map((el, index) => (
                    <HerologosItem key={index} className="logo-box">
                      <Image src={el.src} alt={el.alt || "Logo"} />
                    </HerologosItem>
                  ))}
              </HerologosList>
            </HeroWrapperText>
          </Col>
          {data &&
            data.items[4].images.map((el, index) => (
              <HeroCenterIcon
                key={el?.path}
                onClick={() => tabActive("translate-images-body")}
              >
                <Image key={index} src={el.src} alt={el.alt || "Logo"} />
              </HeroCenterIcon>
            ))}
          <Col lg={6}>
            <PageHeaderImgWrap>
              <StyledBG>
                {data &&
                  data.items[3].images.map((el, index) => (
                    <Image key={index} src={el.src} alt={el.alt || "Logo"} />
                  ))}
              </StyledBG>
            </PageHeaderImgWrap>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
};

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
};

export default HeroArea;

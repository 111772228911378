import React, { Suspense, lazy, useState } from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { normalizedData } from '@utils'
import Seo from '@components/seo'
import Layout from '@layout'
import Header from '@layout/header/layout-01'
import StickyMenu from '@containers/sticky-menu'
import HeroArea from '@containers/landing/hero'
import TranslateImageBox from "@containers/landing/translate-image-box/layout-01"
import TechnologySection from "@containers/technology/layout-04"
import ReachOver from '@containers/landing/reach-over'
import HumanOversight from '@containers/landing/human-oversight'
import TestimonialArea from '@containers/testimonial/layout-03'
import CaseStudy from '@containers/global/case-study/layout-01'
import BlogSection from '@containers/global/blog-section'
import DiveDeeper from '@containers/landing/dive-deeper'
import { caseStudiesData, blogsData } from '@constants'
import CompanyReviews from '@containers/global/company-reviews'
import UseScroll from '../../containers/scroll'
const Footer = lazy(() => import("@layout/footer/layout-01"))
const AndovarHomePage = React.memo(({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || [])
  const globalContent = normalizedData(data?.allGeneral.nodes || [])
  UseScroll((scrollTop) => {
    if (scrollTop > 200) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Global Translation Services | Global Translation Company"
        description="Global, premium translation and localization services for businesses in over 200 language pairs. Translate your media, apps, and more into any language."
      />
      <Header
        data={{
          ...globalContent['menu'],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content['home-sticky-menu']} />

      <main className="site-wrapper-reveal">
        <HeroArea data={content['hero-section']} />
        <TranslateImageBox data={content["translate-images-body"]} />
        <TechnologySection data={content["technology-section-data"]} />
        {
          showRestComponents && <>
            <ReachOver data={content['quality-assurance']} />
            <HumanOversight data={content['human-oversight']} />
            <TestimonialArea layout={2} data={content['testimonial-section']} />
            <CompanyReviews layout={2} data={content['companyReviewsData']} />
            <CaseStudy layout={2} data={caseStudiesData} />
            <DiveDeeper data={content['dive-deeper']} />
            <BlogSection layout={1} data={blogsData} />
          </>
        }

      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  )
})

export const query = graphql`
  query andovarHomePageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "andovar" }, pageType: { eq: "homepage" }) {
      content {
        ...PageContent
      }
    }
  }
`

AndovarHomePage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
}

export default AndovarHomePage


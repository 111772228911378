export default function HeroQuoteForm() {
  window.hsFormsOnReady = window.hsFormsOnReady || [];
  const script = document.createElement("script");
  script.src = "//js.hsforms.net/forms/embed/v2.js";
  script.type = "text/javascript";
  script.charset = "utf-8";
  script.defer = true;
  script.async = true;
  script.loading = "lazy";
  script.onload = "hubcache.letsgo()";
  document.body.appendChild(script);
  script.addEventListener("load", () => {
    window.hsFormsOnReady.push(() => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6085627",
          formId: "ea57b961-7f3f-4171-8b37-17234786ca57",
          target: "#heroQuote",
          onFormReady: function($form) {
            // Inject your custom CSS into the iframe
            const iframe = document.querySelector('#heroQuote iframe');
            if (iframe) {
              const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
              const style = document.createElement('style');
              style.innerHTML = `
                .hbspt-form {
                    height: 60px;
                }
                .hbspt-form:has(.no-list){
                    height: 80px;
                }
                form.hs-form-private {
                    position: relative;
                    max-width: 445px;
                    margin: 0 0;
                }
                form.hs-form-private:has(.no-list) {
                    margin: 0 0 10px !important;
                }
                form.hs-form-private .hs_email label {
                    display: none;
                }

                form.hs-form-private .input input[type="email"] {
                    height: 50px;
                    border-radius: 15px;
                    background-color: #ffffff;
                    padding: 10px 170px 10px 22px !important;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 29px;
                    color: #000000;
                    font-family: Centurygothic;
                    font-weight: 500;
                }

                form.hs-form-private .hs_email {
                    margin: 0 0!important;
                }

                form.hs-form-private .no-list:has(> li:empty) {
                    display: none;
                }

                form.hs-form-private .hs_submit {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 2px;
                }

                form.hs-form-private .hs_submit .actions {
                    margin: 0;
                    padding: 0;
                }

                form.hs-form-private .hs_submit .actions input[type="submit"] {
                    height: 46px;
                    border-radius: 15px;
                    min-width: 80px;
                    background-color: #fa4f19;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 1;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: Centurygothic;
                    padding: 10px 15px;
                }

                form.hs-form-private .no-list {
                    margin: 0;
                    padding: 0;
                }

                form.hs-form-private  .hs_error_rollup {position: absolute;top: calc(100% + 10px);}

                form.hs-form-private .no-list .hs-main-font-element {
                    margin: 0;
                    line-height: 1;
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #fa4f19;
                    font-family: Centurygothic;
                }
                @media (max-width:575px){
                  form.hs-form-private .hs_submit .actions input[type="submit"] {
                      font-size: 14px;
                  }
                  form.hs-form-private .input input[type="email"] {
                      padding: 10px 155px 10px 15px !important;
                      font-size: 14px;
                  }
                }
              `;
              iframeDoc.head.appendChild(style);
            }
          },
        });
      }
    });
  });
}
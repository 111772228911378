import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import SectionTitle from "@ui/section-title";
// import Accordion from "@ui/accordion";
import { DiveDeeperWrapper, DiveDeeperInner } from "./style";
const Accordion = React.lazy(() => import('@ui/accordion'));
const SectionTitle = React.lazy(() => import('@ui/section-title'));

const DiveDeeper = React.memo(({ data }) =>{
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <DiveDeeperWrapper id={data.section}>
      <DiveDeeperInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {data?.items && (
                <Accordion disableDefaultActive layout={2} data={data.items} />
              )}
            </Col>
          </Row>
        </Container>
      </DiveDeeperInner>
    </DiveDeeperWrapper>
    </React.Suspense>
)
  );
})
export default DiveDeeper;
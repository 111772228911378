import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import SwiperSlider, { SwiperSlide } from "@ui/swiper";
import { SectionTitleType, ItemType } from "@utils/types";
import {
    CaseStudyWrap,
    CasestudyContentwrap,
    ImageWrap,
    CaseStudyTextWrap,
    ButtonWrapTop,
    ButtonWrap
} from "./style";
import { Link } from 'gatsby'
import Heading from '@ui/heading'
import Text from '@ui/text'
import Button from '@ui/button'
import Anchor from '@ui/anchor'
import Image from '@ui/image'
const sliderSettings = {
    slidesPerView: 2,
    loop: true,
    pagination: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        992: {
            slidesPerView: 2,
        },
        1199: {
            slidesPerView: 3,
        },
    },
};

const CaseStudySlider = ({
    HeadingStyle,
    data,
    dataSinglePage,
    layout,
    ...props }) => {
    return (
        <>
            <CaseStudyWrap layout={layout} {...props} id={data.section}>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={8}>
                            <SectionTitle
                                title={
                                    dataSinglePage?.section_title
                                        ? dataSinglePage.section_title.title
                                        : data.section_title?.title
                                }
                                subtitle={
                                    dataSinglePage?.section_title
                                        ? dataSinglePage.section_title.subtitle
                                        : data.section_title?.subtitle
                                }
                                description={
                                    dataSinglePage?.section_title
                                        ? dataSinglePage.section_title.description
                                        : data.section_title?.description
                                }
                            />
                        </Col>
                        <Col lg={4}>
                            <ButtonWrapTop>
                                {data?.buttons?.map(({ id, content, ...rest }) => (
                                    <Button key={id} m='7px' {...rest}>
                                        {content}
                                    </Button>
                                ))}
                            </ButtonWrapTop>
                        </Col>
                    </Row>     
                    {data?.items?.length && (
                        <SwiperSlider options={sliderSettings} paginationTop="40px">
                            {data &&
                            data.items.map((el, index) => (
                                <SwiperSlide key={index}>
                                    <CasestudyContentwrap>
                                        <ImageWrap>
                                            <Image
                                                src={el.images[0].src}
                                                alt={el.images[0]?.alt || 'Icon'}
                                            />
                                        </ImageWrap>
                                        <CaseStudyTextWrap>
                                            <Heading as='h6'>{el.title}</Heading>
                                            <Text>{el.description}</Text>
                                            <ButtonWrap>
                                                <Anchor as={Link} to={el.path} key={index}>
                                                    READ MORE
                                                </Anchor>
                                            </ButtonWrap>
                                        </CaseStudyTextWrap>
                                    </CasestudyContentwrap>
                              
                                  </SwiperSlide>
                            ))}
                        </SwiperSlider>
                    )}
                 
                </Container>
            </CaseStudyWrap>
        </>
    );
};

CaseStudySlider.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default CaseStudySlider;

import styled, { device } from "@styled";

export const SectionWrap = styled.section`
    position: relative;
    z-index: 1;
    padding:20px 0 40px 0;
    ${device.small} {
        padding:40px 0 60px 0;
    }
    ${device.xlarge} {
        padding:60px 0 80px 0;
    }
`;
export const TranslateImgWrapper = styled.div`
    display:grid;
    align-items:center;
    justify-content:center;
    gap: 25px;
    align-items: flex-start;
    .translateImgCol{
        transition:all .6s ease-in-out;
        position:relative;
    }
    ${device.medium}{
        grid-template-columns: repeat(2, 1fr);
        .translateImgCol{
            &:nth-child(1){
                align-self:flex-end;
            }
        }
    }
    ${device.large} {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px 15px;
        .translateImgCol{
            &:hover{
                transform:translateY(-10px);
            }
            &:nth-child(3){
                align-self:flex-end;
            }
            &:nth-child(1){
                h4{
                    min-height:45px;
                }
            }  
        }
    }
    ${device.xlarge} {
        gap:25px;
        .translateImgCol{
            &:nth-child(1){
                h4{
                    min-height:54px;
                }
            }
        }
    }
    ${device.xxlarge} {
        gap: 30px 30px;
        .translateImgCol{
            &:nth-child(1){
                h4{
                    min-height:64px;
                }
            }
        }
    }
`;
export const TranslateImgBox = styled.div`
    img{
        max-width:100%;
    }
`;
export const TranslateText = styled.div`
    position:absolute;
    bottom:35px;
    left:0;
    padding:0 15px 0 20px;
    ${device.xlarge} {
        bottom:35px;
        padding:0 15px 0 30px;
    }
    h4{
        font-size: 20px;
        letter-spacing: 0px;
        line-height: 1.143;
        color: #ffffff;
        font-weight: 700;
        margin:0;
        max-width:175px;
        ${device.xlarge} {
            font-size: 24px;
        }
        ${device.xxlarge} {
            font-size: 28px;
        }
    }
    .desc{
        font-size:15px;
        color:#fff;
        text-align:center;
        line-height:1.2667;
        margin-top:15px;
    }
`;
export const TranslateBottomBox = styled.div`
    text-align: center;
    padding: 25px 15px;
    margin-top: 30px;
    border-radius: 10px;
    position:relative;
    background-image: linear-gradient(0deg, #15054f 0%, #561783 99%, #561783 100%);
    width: calc(100% - 8px);
    margin-left: auto;
    ${device.small} {
        width: calc(100% - 10px);
        margin-top: 40px;
        padding: 35px 15px;
    }
    ${device.large} {
        margin-top: 50px;
        padding: 55px 20px;
    }
    &::before{
        content:'';
        position:absolute;
        bottom:-8px;
        left:-8px;
        width:100%;
        max-width: 100px;
        height:calc(100% - 25px);
        background-color: #fa4f19;
        border-radius: 10px;
        z-index:-1;
        ${device.small} {
            bottom:-10px;
            left:-10px;
            max-width: 150px;
            height:calc(100% - 30px);
        }
        ${device.large} {
            max-width: 194px;
            height:calc(100% - 43px);
        }
    }
    h3{
        font-size: 26px;
        letter-spacing: 0px;
        color: #ffffff;
        font-weight: 700;
        line-height:1;
        margin:0 0 10px;
        ${device.small} {
            font-size: 32px;
            margin:0 0 15px;
        }
        ${device.large} {
            font-size: 40px;
            margin:0 0 20px;
        }
    }
    .customBtn{
        font-size: 22px;
        letter-spacing: 0px;
        color: #fa4f19;
        font-weight: 700;
        line-height:1;
        transition:all .5s ease-in-out;
        &:hover{
            text-decoration:underline;
        }
        ${device.small} {
            font-size:26px;
        }
        ${device.large} {
            font-size:30px;
        }
    }
`
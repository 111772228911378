import styled, { device,themeGet } from "@styled";
import blueWaveGradientBg from "@data/images/bg/technologyWaveBg.png";

export const ReachoverWrapper = styled.section`
background-image: url(${blueWaveGradientBg});
background-position: left top;
background-size: cover;
background-repeat: no-repeat;
  padding: 85px 0;
    // padding: 100px 0 40px;
     p {
      color: #fff;
    }
     ${device.small} {
        padding: 120px 0 60px;
    }
    ${device.large} {
        padding: 150px 0 80px;
    }
    ${device.xlarge} {
        padding: 166px 0 100px;
        background-position: left top;
    }
`;
export const ReachoverInner = styled.div``;
export const ReachoverRows = styled.div`
  .section-title{
        margin:0;
        h2{
        color:#fff;
        }
   }  
  .gatsby-image-wrapper {
    max-width: 100%;
  }
  .row {
    &:nth-child(2n) {
      flex-direction: row-reverse;
      margin-top: 40px;
    }
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  a {
    background: ${themeGet("colors.primary")};
    color: #fff !important;
    &:hover {
      text-decoration: none;
      background: ${themeGet("colors.secondaryLight")};
    }
  }
`;

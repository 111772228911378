import React, { useEffect } from "react";
import { FormWrapper, ContactFormWrap, PrivacyPolicyTag } from "./style";
import HeroQuoteForm from "./HeroQuoteForm";
import Anchor from "@ui/anchor";
export default function HeroQuote() {
  useEffect(() => {
    HeroQuoteForm();
  }, []);

  return (
    <>
      <FormWrapper className="contact-form-wrap">
        <ContactFormWrap id="heroQuote"></ContactFormWrap>
        <PrivacyPolicyTag>
          By Submitting this form, you are agreeing to Andovar’s <Anchor path="https://andovar.com/privacy-policy/">
          Privacy Policy.
        </Anchor>
        </PrivacyPolicyTag>
      </FormWrapper>
    </>
  );
}
